<template>
<div>

  <div class="sports_links">
    <button v-if="postion === 1" :class="{'sports_link_active': disp === sportsConst.GAME_DISPLAYPOSITION_1x2}"
            @click="changeSportsDisplay(sportsConst.GAME_DISPLAYPOSITION_1x2)">크로스</button>
    <!--    <button class="bw4 ml-5" :class="{'sports_link_active': disp === sportsConst.GAME_DISPLAYPOSITION_HANDICAPOVERUNDER}"-->
    <!--            @click="changeSportsDisplay(sportsConst.GAME_DISPLAYPOSITION_HANDICAPOVERUNDER)">핸디캡</button>-->
    <button v-if="postion === 1" :class="{'sports_link_active': disp === sportsConst.GAME_DISPLAYPOSITION_SPECIAL}"
            @click="changeSportsDisplay(sportsConst.GAME_DISPLAYPOSITION_SPECIAL)">스페셜</button>

        <button v-if="postion === 1"  :class="{'sports_link_active': disp === sportsConst.GAME_DISPLAYPOSITION_LIVE}"
                @click="changeSportsDisplay(sportsConst.GAME_DISPLAYPOSITION_LIVE)"><i class="fa fa-television"></i> 라이브</button>
<!--      <button class="btn02 bw4" v-if="postion === 1"  :class="{'sports_link_active': disp === sportsConst.GAME_DISPLAYPOSITION_PBA}"-->
<!--              @click="changeSportsDisplay(sportsConst.GAME_DISPLAYPOSITION_PBA)">당구</button>-->
  </div>
</div>


</template>

<script>
  import sportsConst from "../../common/sportsConst";
  import {RECEIVE_DISPLAY_POSTION} from "../../store/mutation-types";

  export default {
    name: "SportsLinksComp",
    props: {
      displayPosition: {
        type: Number,
        default() {
          return sportsConst.GAME_DISPLAYPOSITION_1x2;
        }
      },
      postion:{
        type: Number,
        default() {
          return 1;
        }
      }
    },
    data(){
      return {
        sportsConst,
        disp: this.displayPosition ? this.displayPosition : sportsConst.GAME_DISPLAYPOSITION_1x2,
      }
    },
    methods:{
      changeSportsDisplay(disp) {
        //SportsOriginal.vue -> $on
        this.disp = disp
        this.$store.commit(RECEIVE_DISPLAY_POSTION, 0);
        this.$store.commit(RECEIVE_DISPLAY_POSTION, disp);
      },
      leftBarHandle() {
        this.$bus.$emit('leftBarHandle', true)
      },
    },
  }
</script>

<style scoped>
  .sports_links button{
    color: #060606;
  }
</style>